import React, { useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  CardMedia,
  CardActions,
  CardActionArea,
  Button,
  Link,
} from "@mui/material";
function SuccessStories() {
  const [currentVideo, setCurrentVideo] = useState("");
  const [showVideo, setShowVideo] = useState(false);

  const handlePlayClick = (videoId) => {
    setCurrentVideo(videoId);
    setShowVideo(true);
  };

  const handleCloseClick = () => {
    setShowVideo(false);
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954", // Your primary color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="text-center bg-[#F5F5F5]">
        <div
          className="container"
          style={{ padding: "16px", minHeight: "100vh" }}
        >
          <h1 className="text-center font-bold text-3xl lg:text-5xl my-5">
            Success Stories
          </h1>
          <Grid container className="justify-center">
            <Grid xs={12} md={10}>
              {showVideo ? (
                <div className="w-full h-[300px] lg:h-[500px] relative">
                  <iframe
                    className="w-full h-full"
                    src={`https://www.youtube.com/embed/${currentVideo}`}
                    allowFullScreen
                    title="Project Artha"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                  <button
                    className="absolute top-4 right-4 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                    onClick={handleCloseClick}
                  >
                    Back
                  </button>
                </div>
              ) : (
                // <Grid xs={12} md={10}>
                //   <div className="mt-4 flex flex-col">
                //     <Link
                //       className=" text-white font-bold py-2  cursor-pointer"
                //       onClick={() => handlePlayClick("4brSpSF9P5Y")}
                //     >
                //       1. Educate Women and Save the world
                //     </Link>

                //     <Link
                //       className=" text-white font-bold py-2 cursor-pointer"
                //       onClick={() => handlePlayClick("q5JWp47z4bY")}
                //     >
                //       2. Become Financially Literate
                //     </Link>
                //     {/* Add more buttons for additional videos */}
                //   </div>
                // </Grid>
                <Grid className="justify-center" item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                      "&:hover": {
                        transform: "scale(1.05)", // Scale to 1.1 on hover
                      },
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="140"
                        image="images/13minuteRute.jpg"
                        alt="green iguana"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Sudha Murthy
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Lizards are a widespread group of squamate reptiles,
                          with over 6,000 species, ranging across all continents
                          except Antarctica
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <div>
                        <Link
                          // href="https://youtu.be/zpFgVnKVTUc?si=oOgN-G9nmfy5_Mi6"
                          onClick={() => handlePlayClick("zpFgVnKVTUc")}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <div class="">
                            <a className="abc">
                              <span>Play</span>
                            </a>
                          </div>
                        </Link>
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={4} className="justify-center">
            {/* <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                  "&:hover": {
                    transform: "scale(1.05)", // Scale to 1.1 on hover
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="images/lookingJob.jpg"
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Looking for Job
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Lizards are a widespread group of squamate reptiles, with
                      over 6,000 species, ranging across all continents except
                      Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <div>
                    <Link
                      href="https://www.youtube.com/watch?v=guXxy8LH2QM"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div class="">
                        <a className="abc">
                          <span>Learn</span>
                        </a>
                      </div>
                    </Link>
                  </div>
                </CardActions>
              </Card>
            </Grid> */}

            {/* Add more Grid items for additional resources */}
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default SuccessStories;
