import React, { useState } from "react";
import { Link, Grid } from "@mui/material";

const VideoSwitcher = () => {
  const [currentVideo, setCurrentVideo] = useState("");
  const [showVideo, setShowVideo] = useState(false);

  const handlePlayClick = (videoId) => {
    setCurrentVideo(videoId);
    setShowVideo(true);
  };

  const handleCloseClick = () => {
    setShowVideo(false);
  };

  return (
    <div className="">
      <Grid container className="justify-center">
        <Grid xs={12} md={10}>
          {showVideo ? (
            <div className="w-full h-[300px] lg:h-[500px] relative">
              <iframe
                className="w-full h-full"
                src={`https://www.youtube.com/embed/${currentVideo}`}
                allowFullScreen
                title="Project Artha"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
              <button
                className="absolute top-4 right-4 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                onClick={handleCloseClick}
              >
                Back
              </button>
            </div>
          ) : (
            <Grid xs={12} md={10}>
              <div className="mt-4 flex flex-col">
                <Link
                  className=" text-white font-bold py-2  cursor-pointer"
                  onClick={() => handlePlayClick("4brSpSF9P5Y")}
                >
                  1. Educate Women and Save the world
                </Link>

                <Link
                  className=" text-white font-bold py-2 cursor-pointer"
                  onClick={() => handlePlayClick("q5JWp47z4bY")}
                >
                  2. Become Financially Literate
                </Link>
                {/* Add more buttons for additional videos */}
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default VideoSwitcher;
