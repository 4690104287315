import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  CardMedia,
  CardActions,
  CardActionArea,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { fontFamily } from "@mui/system";

// import { styled } from "@mui/system";
// const ResourceCard = styled(Card)(({ theme }) => ({
//   height: "100%",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "space-between",
//   borderRadius: theme.spacing(2),
//   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
//   transition: "transform 0.3s ease",
//   "&:hover": {
//     transform: "translateY(-5px)",
//   },
// }));

function Resources() {
  // Define custom theme for the resource section
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954", // Your primary color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="text-center bg-[#F5F5F5]">
        <div
          className="container"
          style={{ padding: "16px", minHeight: "100vh" }}
        >
          <h1 className="font-bold text-3xl lg:text-5xl my-5">Resources</h1>
          <Grid container spacing={4} className="justify-center">
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                  "&:hover": {
                    transform: "scale(1.05)", // Scale to 1.1 on hover
                  },
                  fontFamily: "Poppins",
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="images/finance1.jpg"
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      sx={{ fontFamily: "Poppins" }}
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Finance
                    </Typography>
                    <Typography
                      sx={{ fontFamily: "Poppins" }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Lizards are a widespread group of squamate reptiles, with
                      over 6,000 species, ranging across all continents except
                      Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <div>
                    <Link sx={{ fontFamily: "Poppins" }} to="/finance">
                      <div class="">
                        <a className="abc">
                          <span>Explore</span>
                        </a>
                      </div>
                    </Link>
                  </div>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                  "&:hover": {
                    transform: "scale(1.05)", // Scale to 1.1 on hover
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="images/education.jpg"
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      sx={{ fontFamily: "Poppins" }}
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Education
                    </Typography>
                    <Typography
                      sx={{ fontFamily: "Poppins" }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Lizards are a widespread group of squamate reptiles, with
                      over 6,000 species, ranging across all continents except
                      Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <div>
                    <Link to="/education">
                      <div class="">
                        <a className="abc">
                          <span>Explore</span>
                        </a>
                      </div>
                    </Link>
                  </div>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                  "&:hover": {
                    transform: "scale(1.05)", // Scale to 1.1 on hover
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="images/success_story.jpg"
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      sx={{ fontFamily: "Poppins" }}
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Success Stories
                    </Typography>
                    <Typography
                      sx={{ fontFamily: "Poppins" }}
                      variant="body2"
                      color="text.secondary"
                    >
                      Lizards are a widespread group of squamate reptiles, with
                      over 6,000 species, ranging across all continents except
                      Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <div>
                    <Link to="/success_stories">
                      <div class="">
                        <a className="abc">
                          <span>Explore</span>
                        </a>
                      </div>
                    </Link>
                  </div>
                </CardActions>
              </Card>
            </Grid>

            {/* Add more Grid items for additional resources */}
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Resources;
