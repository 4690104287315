import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { Link } from "react-router-dom";
import { fontFamily } from "@mui/system";

function Home() {
  return (
    <div style={{ fontFamily: "Poppins" }}>
      <div className="lg:min-h-screen hero-section text-center lg:pt-20">
        <Grid
          container
          alignItems="center"
          className="text-center container justify-center pt-5"
        >
          <Grid
            item
            xs={12}
            sx={{ fontFamily: "Poppins" }}
            md={8}
            className="lg:pt-20"
          >
            <Typography
              color="#226575"
              variant="h3"
              className="lg:text-4xl"
              sx={{ fontWeight: "bold", mb: 5, fontFamily: "Poppins" }}
            >
              Welcome to Project Artha
            </Typography>

            <h3 className="font-bold text-2xl lg:my-5 md:text-3xl">
              Empowering all with financial literacy for economic prosperity.
            </h3>
            <h4 className="font-bold lg:my-5 md:mx-5 text-2xl md:text-3xl">
              Join us in breaking barriers and building equality.
            </h4>
          </Grid>
          <Grid item md={4} xs={10}>
            <img src="/images/Logo.png" alt="Logo" />
          </Grid>
        </Grid>
      </div>

      <div className="lg:min-h-50% text-center  pt-5 bg-[#]">
        <Grid
          sx={{ fontFamily: "Poppins" }}
          container
          className="container justify-center"
        >
          <Grid
            md={10}
            xs={12}
            className="card  rounded-lg mt-2"
            sx={{ backgroundColor: "#D1F9FF", p: 2, fontFamily: "Poppins" }}
          >
            <div className="">
              <Typography
                variant="h5"
                color="#1F0954"
                sx={{ fontWeight: "bold", mb: 2, fontFamily: "Poppins" }}
              >
                VISION
              </Typography>
              <Typography
                sx={{ fontFamily: "Poppins" }}
                className="px-"
                variant="body1"
              >
                Project Artha aims to empower young women by equipping them with
                the necessary skills to practice artha throughout their lives.
              </Typography>
              <Typography
                color="#1F0954"
                variant="h5"
                sx={{ fontWeight: "bold", my: 2, fontFamily: "Poppins" }}
              >
                MISSION
              </Typography>
              <Typography
                sx={{ fontFamily: "Poppins" }}
                className="px-5 pb-8"
                variant="body1"
              >
                Rooted in the profound principles of artha, we are committed to
                transforming the lives of youth by equipping them with financial
                literacy. Our volunteers embark on mission trips to schools in
                underprivileged regions around the world, where they teach a
                comprehensive curriculum on financial literacy. Our commitment
                doesn't end with these trips; we maintain an ongoing
                relationship with our students by providing mentorship, and
                supporting them as they navigate adulthood. Project Artha
                strives to foster an environment where all youth can sustain
                their financial means.
              </Typography>
            </div>
          </Grid>
          {/* <Grid
            md={5}
            xs={12}
            className="md:ml-5 vm-card rounded-lg mt-2"
            sx={{ backgroundColor: "#fff", p: 2 }}
          >
            <Typography
              color="#1F0954"
              variant="h5"
              sx={{ fontWeight: "bold", mb: 2 }}
            >
              MISSION
            </Typography>
            <Typography variant="body1">
              Rooted in the profound principles of artha, we are committed to
              transforming the lives of youth by equipping them with financial
              literacy. Our volunteers embark on mission trips to schools in
              underprivileged regions around the world, where they teach a
              comprehensive curriculum on financial literacy. Our commitment
              doesn't end with these trips; we maintain an ongoing relationship
              with our students by providing mentorship, and supporting them as
              they navigate adulthood. Project Artha strives to foster an
              environment where all youth can sustain their financial means.
            </Typography>
          </Grid> */}

          <div className="container text-center mb-20 mt-8">
            {/* <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
              Help Project Artha make a difference
            </Typography> */}
            <Typography
              variant="body1"
              className="md:px-10 "
              sx={{ mb: 3, fontFamily: "Poppins" }}
            >
              Our organization is committed to teaching young women about
              finances. With your support, we can make a positive impact on the
              world. Join us today!
            </Typography>
            <div
              style={{ display: "flex", justifyContent: "center" }}
              class=" text-center mt-10 wrapper"
            >
              <a className="ab">
                <span>Find Out More!</span>
              </a>
            </div>
          </div>
        </Grid>
      </div>

      <div className=" container my-20 text-center ">
        <Typography
          variant="body"
          className="text-xl lg:text-3xl "
          sx={{ fontWeight: "bold", py: 10, fontFamily: "Poppins" }}
        >
          Become part of Project Artha
        </Typography>
        <Grid container className="justify-center">
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                to="/mentorlogin"
                style={{ textDecoration: "none", width: "200px" }}
              >
                <div class=" mt-10 wrapper">
                  <a className="ab">
                    <span>Login as Mentor</span>
                  </a>
                </div>
              </Link>
            </div> */}
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                to="/mentorlogin"
                style={{ textDecoration: "none", width: "200px" }}
              >
                <div class=" mt-10 mb-4 wrapper">
                  <a className="ab">
                    <span>SignUp as Mentor</span>
                  </a>
                </div>
              </Link>
            </div> */}

          {/* <div className="ml-[9rem] w-[16rem] become_part">
              <img src="/images/become_part.png" alt="" />
            </div> */}

          <Grid className="mt-10" item xs={12} md={8}>
            <Carousel autoPlay={true} animation="slide">
              <img src="/images/caro1.jpg" alt="" />
              <img src="/images/caro2.jpg" alt="" />
              <img src="/images/caro3.jpg" alt="" />
              <img src="/images/caro4.jpg" alt="" />

              {/* Add more carousel items as needed */}
            </Carousel>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Home;
