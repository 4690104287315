import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Link,
  Paper,
  InputAdornment,
  ThemeProvider,
  createTheme,
  Modal,
  Box,
} from "@mui/material";
import { Email, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954", // Your primary color
      },
    },
    typography: {
      fontFamily: "Arial, sans-serif",
    },
  });

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [loginType, setLoginType] = useState("student"); // Default to Student login

  const handleLogin = () => {
    let loginUrl = "";
    switch (loginType) {
      case "student":
        loginUrl =
          "http://projectarthabackend.ajinkyatechnologies.com/api/student/students/login";
        break;
      case "mentor":
        loginUrl =
          "http://projectarthabackend.ajinkyatechnologies.com/api/mentor/mentors/login";
        break;
      case "admin":
        loginUrl =
          "http://projectarthabackend.ajinkyatechnologies.com/api/admin/login";
        break;
      default:
        loginUrl = "";
    }

    axios
      .post(loginUrl, { email, password })
      .then((response) => {
        setSuccessModalOpen(true);
        // Redirect to '/course' after 2 seconds
        setTimeout(() => navigate("/course"), 2000);
      })
      .catch((error) => {
        setError("Invalid email or password. Please try again.");
      });
  };

  const handleModalClose = () => {
    setSuccessModalOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          maxWidth: "400px",
          margin: "auto",
          marginTop: "50px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Sign in to your account
        </Typography>
        {/* Toggle buttons */}
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            variant={loginType === "student" ? "contained" : "outlined"}
            style={{ marginRight: "10px" }}
            onClick={() => setLoginType("student")}
          >
            Student
          </Button>
          <Button
            variant={loginType === "mentor" ? "contained" : "outlined"}
            style={{ marginRight: "10px" }}
            onClick={() => setLoginType("mentor")}
          >
            Mentor
          </Button>
          {/* <Button
          variant={loginType === "admin" ? "contained" : "outlined"}
          onClick={() => setLoginType("admin")}
        >
          Admin
        </Button> */}
        </div>
        <form>
          <TextField
            label="Your email"
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email color="primary" />
                </InputAdornment>
              ),
            }}
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock color="primary" />
                </InputAdornment>
              ),
            }}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <Typography
              variant="body2"
              style={{ color: "red", marginBottom: "10px" }}
            >
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            fullWidth
            style={{
              backgroundColor: "#1F0954",
              color: "#ffffff",
              marginBottom: "10px",
            }}
            onClick={handleLogin}
          >
            Sign in
          </Button>
          <Typography
            variant="body2"
            style={{ textAlign: "center", fontFamily: "Arial, sans-serif" }}
          >
            Don’t have an account yet?{" "}
            <Link
              href="#"
              style={{
                color: "#1F0954",
                fontWeight: "bold",
                fontFamily: "Arial, sans-serif",
              }}
            >
              Sign up
            </Link>
          </Typography>
        </form>
      </Paper>

      {/* Success Modal */}
      <Modal
        open={successModalOpen}
        onClose={handleModalClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            id="success-modal-title"
            gutterBottom
            style={{ fontFamily: "Arial, sans-serif" }}
          >
            Login Successful
          </Typography>
          <Typography
            variant="body1"
            id="success-modal-description"
            style={{ fontFamily: "Arial, sans-serif" }}
          >
            Redirecting to course page...
          </Typography>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default Login;
