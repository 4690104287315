import React, { useState } from "react";

import {
  Typography,
  Grid,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  CardMedia,
  CardActions,
  CardActionArea,
  // Link,
} from "@mui/material";
import { Link } from "react-router-dom";
function Finance() {
  // const [currentVideo, setCurrentVideo] = useState("");
  // const [showVideo, setShowVideo] = useState(false);

  // const handlePlayClick = (videoId) => {
  //   setCurrentVideo(videoId);
  //   setShowVideo(true);
  // };

  // const handleCloseClick = () => {
  //   setShowVideo(false);
  // };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954", // Your primary color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="text-center bg-[#F5F5F5]">
        <div
          className="container"
          style={{ padding: "16px", minHeight: "100vh" }}
        >
          <h1 className="text-center font-bold text-3xl lg:text-5xl my-5">
            Finance
          </h1>
          <Grid container className="justify-center">
            <Grid container spacing={4} className="justify-center">
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                    "&:hover": {
                      transform: "scale(1.05)", // Scale to 1.1 on hover
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image="images/budgeting.jpg"
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Budgeting
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Lizards are a widespread group of squamate reptiles,
                        with over 6,000 species, ranging across all continents
                        except Antarctica
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <div>
                      <Link
                        to="https://img1.wsimg.com/blobby/go/a0635672-0d21-45db-b668-4f23935e3d30/Day%20-%201%20-%20Intro%20%26%20Budgeting.pdf"
                        style={{ textDecoration: "none", color: "inherit" }}
                        // onClick={handlePlayClick(
                        //   "https://img1.wsimg.com/blobby/go/a0635672-0d21-45db-b668-4f23935e3d30/Day%20-%201%20-%20Intro%20%26%20Budgeting.pdf"
                        // )}
                      >
                        <div class="">
                          <a className="abc">
                            <span>Learn</span>
                          </a>
                        </div>
                      </Link>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                    "&:hover": {
                      transform: "scale(1.05)", // Scale to 1.1 on hover
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image="images/investing.jpg"
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Invest Your Saving
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Lizards are a widespread group of squamate reptiles,
                        with over 6,000 species, ranging across all continents
                        except Antarctica
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <div>
                      <Link
                        to="https://img1.wsimg.com/blobby/go/a0635672-0d21-45db-b668-4f23935e3d30/Day%203%20-%20SAVING%20AND%20INVESTING.pdf"
                        style={{ textDecoration: "none", color: "inherit" }}
                        target="_blank"
                      >
                        <div class="">
                          <a className="abc">
                            <span>Learn</span>
                          </a>
                        </div>
                      </Link>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                    "&:hover": {
                      transform: "scale(1.05)", // Scale to 1.1 on hover
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image="images/interest.jpg"
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Interest
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Lizards are a widespread group of squamate reptiles,
                        with over 6,000 species, ranging across all continents
                        except Antarctica
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <div>
                      <Link
                        to="https://img1.wsimg.com/blobby/go/a0635672-0d21-45db-b668-4f23935e3d30/Day%202%20-%20COMPOUNDING%20INTEREST.pdf"
                        style={{ textDecoration: "none", color: "inherit" }}
                        target="_blank"
                      >
                        <div class="">
                          <a className="abc">
                            <span>Learn</span>
                          </a>
                        </div>
                      </Link>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                    "&:hover": {
                      transform: "scale(1.05)", // Scale to 1.1 on hover
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image="images/loans.jpg"
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Loans
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Lizards are a widespread group of squamate reptiles,
                        with over 6,000 species, ranging across all continents
                        except Antarctica
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <div>
                      <Link
                        to="https://img1.wsimg.com/blobby/go/a0635672-0d21-45db-b668-4f23935e3d30/Day%203%20-%20LOANS.pdf"
                        style={{ textDecoration: "none", color: "inherit" }}
                        target="_blank"
                      >
                        <div class="">
                          <a className="abc">
                            <span>Learn</span>
                          </a>
                        </div>
                      </Link>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    transition: "transform 0.4s ease-in-out", // Add transition property for smooth scaling
                    "&:hover": {
                      transform: "scale(1.05)", // Scale to 1.1 on hover
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image="images/retirement.jpg"
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Retirement
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Lizards are a widespread group of squamate reptiles,
                        with over 6,000 species, ranging across all continents
                        except Antarctica
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <div>
                      <Link
                        to="https://img1.wsimg.com/blobby/go/a0635672-0d21-45db-b668-4f23935e3d30/Day%204%20-%20RETIREMENT.pdf"
                        style={{ textDecoration: "none", color: "inherit" }}
                        target="_blank"
                      >
                        <div class="">
                          <a className="abc">
                            <span>Learn</span>
                          </a>
                        </div>
                      </Link>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Finance;
